import type { FC } from 'react';

import type { HeroRenderProps } from '../../../../components/Hero';
import { Hero } from '../../../../components/Hero';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { LatestGalleryCardHero } from './LatestGalleryCardHero';
import { latestGalleryCardHeroQuery } from './query';
import type { LatestGalleryCardHeroProps, LatestGalleryCardHeroResponse } from './types';

export const LatestGalleryCardHeroShallow: FC<{ id: string }> = ({ id }) => {
  const { data } = useContentfulQuery<LatestGalleryCardHeroResponse, ContentfulIdVariable>(
    latestGalleryCardHeroQuery,
    { variables: { id } }
  );

  if (!data?.latestGalleryCardHero) {
    return null;
  }

  // If Gallery information does not exist, render the standard Hero
  if (!data.latestGalleryCardHero.gallery) {
    const heroProps: HeroRenderProps = {
      sys: data.latestGalleryCardHero.sys,
      __typename: 'BlockHero',
      title: data.latestGalleryCardHero.title,
      body: data.latestGalleryCardHero.body,
    };

    return <Hero {...heroProps} />;
  }

  // Otherwise render the custom component
  const cardHeroProps: LatestGalleryCardHeroProps = {
    sys: data.latestGalleryCardHero.sys,
    eyebrowText: data.latestGalleryCardHero.eyebrowText,
    title: data.latestGalleryCardHero.title,
    body: data.latestGalleryCardHero.body,
    galleryId: data.latestGalleryCardHero.gallery.sys.id,
    defaultTileImage: data.latestGalleryCardHero.gallery.defaultImage,
    analytics: data.latestGalleryCardHero.analytics,
  };

  return <LatestGalleryCardHero {...cardHeroProps} />;
};
