import { gql } from '@apollo/client';

import { fragments as analyticsFragments } from '../../../../components/Analytics/query';
import { fragments as imageFragments } from '../../../../components/Image';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';

export const fragment = gql`
  fragment latestGalleryCardHero on LatestGalleryCardHero {
    ...ContentfulSysId
    title
    body
    eyebrowText
    gallery {
      ... on Gallery {
        ...ContentfulSysId
        defaultImage {
          ...ImageAll
        }
      }
    }
    analytics {
      ...AnalyticsAll
    }
  }
  ${contentfulSysIdFragment}
  ${analyticsFragments.all}
  ${imageFragments.all}
`;

export const latestGalleryCardHeroQuery = gql`
  query latestGalleryCardHeroQuery($preview: Boolean!, $locale: String!, $id: String!) {
    latestGalleryCardHero(preview: $preview, locale: $locale, id: $id) {
      ...latestGalleryCardHero
    }
  }
  ${fragment}
`;
