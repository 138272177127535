import type { TileQueryResponse } from '../../../../components/Gallery';
import { tileCollectionQuery } from '../../../../components/Gallery';
import { useGalleryTiles } from '../../../../components/Gallery/useGalleryTiles';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import type { LatestGalleryCardProps } from './types';

export function useLatestGalleryTile(galleryId: string): LatestGalleryCardProps {
  // TODO: consolidate this into a single request (blocked while we use ElasticSearch for gallery pagination)
  // Get list of tiles
  const { paginationData, isLoading } = useGalleryTiles({
    galleryId,
    page: 1,
    pageSize: 1,
    tags: [],
  });

  // Get latest tile data
  const { data: tile, loading: loadingTile } = useContentfulQuery<
    TileQueryResponse,
    { ids: string[] }
  >(tileCollectionQuery, {
    variables: { ids: [paginationData?.tiles?.[0]?.tileSysId ?? ''] },
  });

  return {
    tile: tile?.tileCollection?.items[0],
    isLoading: loadingTile || isLoading,
  };
}
